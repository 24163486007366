@use 'sass:color'

$dark: #2A3947
$light: rgb(222, 239, 255)
$link: #fe8181
$highlight: #ffcc67fb
$dark-grey: #2A3947
$grey: #505c67

/* -------------------------------------------------------------------------- */
/*                                    MAIN                                    */
/* -------------------------------------------------------------------------- */

body
    background-color: $light !important
    min-height: 100vh
    min-width: 100vw
    font-family: Rosario
    color: white
    font-size: 1.0rem
    cursor: default
    text-align: justify
    text-justify: inter-word
    padding-top: 7rem !important

    // a
    //     color: $link
    //     font-weight: 800

    // a:hover
    //     color: $highlight
    //     font-weight: 900
    //     text-decoration: underline

    // .link-no-style
    //     color: white

*
  scrollbar-width: none
  -ms-overflow-style: none


body::-webkit-scrollbar 
  display: none

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */

.blur-background 
  background-color: rgba(235, 246, 255, 0.4)
  backdrop-filter: blur(5px)
  -webkit-backdrop-filter: blur(10px)
  padding: 20px
  border-radius: 10px



/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

.temp
    background-color: red
    height: 140vh

.header
    width: 95%
    border: 3px solid $grey
    margin: 0 auto
    border-radius: 12px
    font-size: 1.6rem
    padding: 0.4rem
    position: fixed
    top: 1.8rem
    left: 50%
    transform: translate(-50%, 0%)
    z-index: 1000
    font-family: Poppins

    .level-item 
        &:hover
            // scale up
            transform: scale(1.03)
            transition: transform 0.1s
            cursor: pointer

    .header-title
        margin-left: 20px
        color: $dark-grey
        font-weight: 500

    .header-item
        margin: 0 2rem
        font-weight: 300
        font-size: 1.1rem